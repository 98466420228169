.agreement-container {
  display: flex;
  flex: 1;
  height: 100vh;
  border: solid;
  background-color: gray;
}

.agreement-container-vertical {
  height: 50vh;
}

.agreement-checkbox {
  font-size: 2.5vh;
  font-family: "BMDOHYEON";
  font-weight: bold;
}

.agreement-details {
  font-size: 2.5vh;
  font-family: "BMDOHYEON";
  font-weight: bold;
}

.agreement-agree {
  padding-left: 3vw;
}

.pdf-editor {
  flex-basis: 70%;
}

.agreement-documents {
  margin: 1vw;
  border: solid;
}

.container-participant {
  width: 100%;
}

.agreement-participant {
  justify-content: center;
  border: solid;
  padding: 5px;
  height: 100%;
}

.agreement-checklist {
  padding: 10% 10% 10% 10%;
}

.participant-image {
  max-width: 100%;
  max-height: 100%;
}

.top-navigation {
  height: 10%;
  width: 100%;
  border: solid;
}

.top-navigation-menu {
  width: 17%;
  border: solid;
}

.top-navigation-menu-empty {
  width: 17%;
}

.container-editor {
  height: 100%;
  border: solid;
  justify-content: center;
}
