.my-page {
  font-family: "yg-jalnan";
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.my-page-header {
  display: block;
  margin-left: auto;
  margin-right: 0;
  padding: 20px;
}

.my-page-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10vh;
  height: 100%;
}

.my-page-content {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid;
  border-radius: 25%;
  width: 300px;
  height: 300px;
  background-color: #f5edd3;
  margin: 50px;
  cursor: pointer;
}
